import { styled as styledMui5 } from '@mui/material/styles';

export const DividerMui5 = styledMui5('div')`
  width: 80px;
  height: 2px;
  margin: 8px auto 16px;
  background: ${(props) => props.theme.palette.secondary.main};
`;

export const DividerLeftMui5 = styledMui5('div')`
  width: 80px;
  height: 2px;
  margin: 8px 0 16px;
  background: ${(props) => props.theme.palette.secondary.main};
`;

export const SmallDividerMui5 = styledMui5('div')`
  width: 40px;
  height: 2px;
  margin: 8px auto;
  background: ${(props) => props.theme.palette.secondary.main};
`;
