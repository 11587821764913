import { styled } from '@mui/material/styles';
import { blue } from 'theme/colors';

const StripesBackground = styled('div')(({ theme }) => ({
  marginTop: -80,
  paddingTop: 80,
  backgroundColor: theme.palette.grey[100],
  backgroundImage: `linear-gradient(
    135deg,
    transparent 60%,
    ${theme.palette.grey[50]} 60%,
    ${theme.palette.grey[50]} 70%,
    ${blue[25]} 70%,
    ${blue[25]} 80%,
    ${blue[50]} 80%,
    ${blue[50]} 90%,
    transparent
  )
`,
}));

export default StripesBackground;
