import { useEffect, useState } from 'react';

const TYPING_FINISH_DELAY = 2000;
const TYPING_START_DELAY = 200;
const TYPING_WRITE_DELAY = 100;
const TYPING_CLEAR_DELAY = 50;

type TypingPlaceholderProps = {
  finishDelay?: number;
  startDelay?: number;
  writeDelay?: number;
  clearDelay?: number;
  placeholders: string[];
};

export function useTypingPlaceholder({
  finishDelay = TYPING_FINISH_DELAY,
  startDelay = TYPING_START_DELAY,
  writeDelay = TYPING_WRITE_DELAY,
  clearDelay = TYPING_CLEAR_DELAY,
  placeholders,
}: TypingPlaceholderProps) {
  const [caretIndex, setCaretIndex] = useState(0);
  const [placeholder, setPlaceholder] = useState('');

  useEffect(() => {
    let place = '';
    const typePlaceholder = () => {
      const typingInterval = setInterval(() => {
        if (place.length < placeholders[caretIndex].length) {
          place += placeholders[caretIndex][place.length];
          setPlaceholder(place);
        } else {
          clearInterval(typingInterval);
          setTimeout(() => deletePlaceholder(), finishDelay);
        }
      }, writeDelay);

      return typingInterval;
    };

    const deletePlaceholder = () => {
      const deletingInterval = setInterval(() => {
        if (place.length > 0) {
          place = place.slice(0, place.length - 1);
          setPlaceholder(place);
        } else {
          clearInterval(deletingInterval);
          setTimeout(
            () => setCaretIndex((caretIndex + 1) % placeholders.length),
            startDelay,
          );
        }
      }, clearDelay);

      return deletingInterval;
    };

    const typingInterval = typePlaceholder();

    return () => clearInterval(typingInterval);
  }, [
    caretIndex,
    clearDelay,
    finishDelay,
    placeholders,
    startDelay,
    writeDelay,
  ]);

  return placeholder;
}
