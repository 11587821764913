import {
  CT_CITIES_BY_ZIP,
  MD_CITIES_BY_ZIP,
  NJ_CITIES_BY_ZIP,
  NY_CITIES_BY_ZIP,
  PA_CITIES_BY_ZIP,
  CA_CITIES_BY_ZIP,
} from '../services/geo';
import { EnumStateName } from './constants';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

export type SeoLink = {
  url: string;
  name: string;
};

export const makesModelsLinks: SeoLink[] = [
  // Toyota
  {
    name: 'Toyota Highlander',
    url: '/deals?make=Toyota&model=Highlander',
  },
  {
    name: 'Toyota RAV4',
    url: '/deals?make=Toyota&model=RAV4',
  },
  {
    name: 'Toyota Camry',
    url: '/deals?make=Toyota&model=Camry',
  },
  // Honda
  {
    name: 'Honda Civic',
    url: '/deals?make=Honda&model=Civic+Sedan',
  },
  {
    name: 'Honda Civic Hatchback',
    url: '/deals?make=Honda&model=Civic+Hatchback',
  },
  {
    name: 'Honda Accord',
    url: '/deals?make=Honda&model=Accord+Sedan',
  },
  // Jeep
  {
    name: 'Jeep Grand Cherokee',
    url: '/deals?make=Jeep&model=Grand+Cherokee',
  },
  {
    name: 'Jeep Wrangler',
    url: '/deals?make=Jeep&model=Wrangler',
  },
  // Kia
  {
    name: 'Kia Sportage',
    url: '/deals?make=Kia&model=Sportage',
  },
  // Audi
  {
    name: 'Audi Q5',
    url: '/deals?make=Audi&model=Q5',
  },
  {
    name: 'Audi Q3',
    url: '/deals?make=Audi&model=Q3',
  },
  // Mercedes
  {
    name: 'Mercedes-Benz C-Class',
    url: '/deals?make=Mercedes-Benz&model=C-Class',
  },
  {
    name: 'Mercedes-Benz GLE',
    url: '/deals?make=Mercedes-Benz&model=GLE',
  },
  // Volvo
  {
    name: 'Volvo XC90',
    url: '/deals?make=Volvo&model=XC90',
  },
  {
    name: 'Volvo XC60',
    url: '/deals?make=Volvo&model=XC60',
  },
  // Mazda
  {
    name: 'Mazda CX-5',
    url: '/deals?make=Mazda&model=CX-5',
  },
  {
    name: 'Mazda CX-30',
    url: '/deals?make=Mazda&model=CX-30',
  },
  // Acura
  {
    name: 'Acura MDX',
    url: '/deals?make=Acura&model=MDX',
  },
  {
    name: 'Acura RDX',
    url: '/deals?make=Acura&model=RDX',
  },
  // Hyundai
  {
    name: 'Hyundai Elantra',
    url: '/deals?make=Hyundai&model=Elantra',
  },
];

const NJ_SEO_LINKS = [...NJ_CITIES_BY_ZIP].slice(0, 6).map(([zip, city]) => ({
  url: `/deals?zipcode=${zip}`,
  name: `${city} NJ`,
}));

const NY_SEO_LINKS = [...NY_CITIES_BY_ZIP].slice(0, 6).map(([zip, city]) => ({
  url: `/deals?zipcode=${zip}`,
  name: `${city} NY`,
}));

const PA_SEO_LINKS = [...PA_CITIES_BY_ZIP].slice(0, 6).map(([zip, city]) => ({
  url: `/deals?zipcode=${zip}`,
  name: `${city} PA`,
}));

const CT_SEO_LINKS = [...CT_CITIES_BY_ZIP].slice(0, 6).map(([zip, city]) => ({
  url: `/deals?zipcode=${zip}`,
  name: `${city} CT`,
}));

const MD_SEO_LINKS = [...MD_CITIES_BY_ZIP].slice(0, 6).map(([zip, city]) => ({
  url: `/deals?zipcode=${zip}`,
  name: `${city} MD`,
}));

const CA_SEO_LINKS = [...CA_CITIES_BY_ZIP].slice(0, 6).map(([zip, city]) => ({
  url: `/deals?zipcode=${zip}`,
  name: `${city} CA`,
}));

export const locationsLinks: SeoLink[] = [
  ...NJ_SEO_LINKS,
  ...NY_SEO_LINKS,
  ...PA_SEO_LINKS,
  ...CT_SEO_LINKS,
  ...MD_SEO_LINKS,
  ...CA_SEO_LINKS,
];

export const statesLinks: SeoLink[] = Object.values(EnumStateName).map(
  (stateName) => ({
    name: stateName,
    url: `/state/${upperFirst(camelCase(stateName))}`,
  }),
);
