import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import {
  SelectControl,
  SelectField,
  SelectOption,
} from 'components/atoms/Select';
import StyledTextField from 'components/atoms/TextField';
import isNumber from 'lodash/isNumber';
import { Field, Form } from 'react-final-form';
import { useRecoilState } from 'recoil';
import { useZipcodeValidator } from 'services/zipcode/hooks/useZipcodeValidator';
import { filterZipcodeAtom } from 'services/zipcode/recoil';
import {
  AVAILABLE_MONTHLY_PAYMENTS,
  BODY_STYLES,
  INVALID_ZIP_CODE_ERROR_MSG,
} from 'shared/utils/constants';
import { useFilters } from '../../../services/filters/hooks/useFilters';
import { grey } from '../../../theme/colors';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { getMonthlyPaymentIndex } from '../../../shared/utils/functions';
import { useRouter } from 'next/router';
import { FromContainer, SearchSubmitButton } from './common';
import { FC } from 'react';
import Image from 'next/image';

const FormControlSelect = styled(FormControl)(({ theme }) => ({
  '&.MuiFormControl-root': {
    borderRight: `1px solid ${grey[300]}`,
    [theme.breakpoints.down('md')]: {
      border: 'none',
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
      '& .MuiFormLabel-root': {
        color: grey[500],
        fontSize: theme.typography.pxToRem(14),
        marginLeft: theme.spacing(1),
        marginTop: 0,
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(3),
          marginLeft: theme.spacing(2),
        },
      },
    },
    '& .MuiInputBase-root > fieldset': {
      border: 0,
      [theme.breakpoints.down('md')]: {
        borderBottom: `1px solid transparent`,
        borderImage: `linear-gradient(
          90deg, 
          transparent 0%, 
          transparent ${theme.spacing(2)}, 
          ${theme.palette.grey[200]} ${theme.spacing(2)}, 
          ${theme.palette.grey[200]} calc(100% - ${theme.spacing(2)}), 
          transparent calc(100% - ${theme.spacing(2)}),
          transparent 100%
        ) 1 stretch`,
      },
    },
    '& .MuiSelect-root': {
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
      '& .MuiSelect-select': {
        lineHeight: theme.typography.pxToRem(16),
        minHeight: theme.typography.pxToRem(16),
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(6),
        paddingBottom: theme.spacing(0.2),
        color: theme.palette.text.primary,
        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
      '& .MuiSelect-icon': {
        bottom: theme.spacing(0.2),
        color: `${theme.palette.text.primary}!important`,
        [theme.breakpoints.down('md')]: {
          bottom: 'unset',
        },
      },
    },
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: 0.5,
    },
  },
}));

const zipcodeInputStyles: (theme: ThemeMui5) => SystemStyleObject<ThemeMui5> = (
  theme,
) => ({
  '&.MuiFormControl-root': {
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: grey[500],
      textTransform: 'capitalize',
      marginLeft: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
      },
    },
    '& .MuiInputBase-formControl': {
      '&.Mui-error > input': {
        color: theme.palette.secondary.main,
      },
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
      '&.Mui-focused > fieldset': {
        [theme.breakpoints.up('md')]: {
          border: 0,
        },
      },
      '& > fieldset': {
        border: 0,
      },
      '& > input': {
        boxSizing: 'content-box',
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(16),
        height: theme.typography.pxToRem(16),
        fontWeight: 'bold',
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(0.2),
        color: theme.palette.text.primary,
        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
        '::placeholder': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
});

const selectDropdownStyles: (
  theme: ThemeMui5,
) => SystemStyleObject<ThemeMui5> = (theme) => ({
  '&.MuiPaper-root': {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(-1),
    },
  },
});

const GreySelectLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.grey[600],
  border: 0,
  textTransform: 'capitalize',
  transform: `translate(${theme.spacing(0)}, ${theme.spacing(-3)}) !important`,
  marginTop: theme.spacing(2),
  '&.Mui-focused': {
    color: theme.palette.grey[600],
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
  },
}));

const CustomGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '3fr 3fr 3fr',
  gridTemplateRows: 'unset',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'unset',
    gridTemplateRows: `1fr 1fr auto`,
    gap: theme.spacing(2),
  },
}));

const LastFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'stretch',
  },
}));

const containerStyles: (theme: ThemeMui5) => SystemStyleObject<ThemeMui5> = (
  theme,
) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 2),
  },
});

const SelectOptionContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  width: '50px',
  height: `calc(100% + ${theme.spacing(2)})`,
  position: 'relative',
}));

const getImagePath = (bodyStyle: string) => {
  return `/img/body_types/${bodyStyle.toLowerCase()}-full.webp`;
};

export type FormInitialValues = {
  budget?: string;
  bodyStyle?: string;
  zipcode?: string;
};

export type SimpleSearchFormProps = {
  onSubmit: (params: Required<FormInitialValues>) => void;
};

const SimpleSearchForm: FC<SimpleSearchFormProps> = ({ onSubmit }) => {
  const [filterZipCode, setFilterZipCode] =
    useRecoilState<string>(filterZipcodeAtom);

  const { isZipcodeValid } = useZipcodeValidator();

  const zipCodeValidator = async (zipcode: string) => {
    if (!zipcode?.length) {
      return;
    }
    const { isValid, error } = await isZipcodeValid(zipcode);
    if (!isValid) {
      return error || INVALID_ZIP_CODE_ERROR_MSG;
    }
  };

  const { filters } = useFilters();
  const monthlyBudgetDefaultIndex = getMonthlyPaymentIndex({
    minMonthlyPayment: Number(filters.minMonthlyPayment),
    maxMonthlyPayment: Number(filters.maxMonthlyPayment),
  });
  const monthlyBudgetDefaultValue =
    monthlyBudgetDefaultIndex < 0 ? '' : String(monthlyBudgetDefaultIndex);

  const router = useRouter();
  let formInitialValues: FormInitialValues;
  switch (router.pathname) {
    case '/deals':
      formInitialValues = {
        budget: monthlyBudgetDefaultValue,
        bodyStyle: filters.body_style,
        zipcode: filters.zipcode,
      };
      break;
    default:
      formInitialValues = { budget: '', bodyStyle: '', zipcode: '' };
      break;
  }

  return (
    <FromContainer sx={containerStyles}>
      <Form
        initialValues={formInitialValues}
        onSubmit={({ budget, bodyStyle, zipcode }) => {
          onSubmit({ budget, bodyStyle, zipcode });
        }}
        render={({ handleSubmit }) => (
          <form className='js-search-deals-form' onSubmit={handleSubmit}>
            <CustomGrid>
              <Field name='budget'>
                {({ input: { name, onChange, value } }) => {
                  return (
                    <FormControlSelect variant='outlined' fullWidth>
                      <SelectControl>
                        <GreySelectLabel>Monthly budget</GreySelectLabel>
                        <SelectField
                          variant='outlined'
                          displayEmpty
                          onChange={onChange}
                          value={value}
                          name={name}
                          dropdownSx={selectDropdownStyles}
                        >
                          <SelectOption value=''>All budgets</SelectOption>
                          {AVAILABLE_MONTHLY_PAYMENTS.map((item, index) => (
                            <SelectOption key={index} value={index}>
                              {item.label}
                            </SelectOption>
                          ))}
                        </SelectField>
                      </SelectControl>
                    </FormControlSelect>
                  );
                }}
              </Field>
              <Field name='bodyStyle'>
                {({ input: { name, onChange, value } }) => {
                  return (
                    <FormControlSelect variant='outlined' fullWidth>
                      <SelectControl>
                        <GreySelectLabel>Body style</GreySelectLabel>
                        <SelectField
                          variant='outlined'
                          displayEmpty
                          onChange={onChange}
                          value={value}
                          name={name}
                          dropdownSx={selectDropdownStyles}
                        >
                          <SelectOption value=''>All body styles</SelectOption>
                          {BODY_STYLES.map((item, index) => (
                            <SelectOption key={index} value={item}>
                              <SelectOptionContainer>
                                <ImageWrapper>
                                  <Image
                                    src={getImagePath(item)}
                                    alt={item}
                                    objectFit='cover'
                                    layout='fill'
                                    sizes='128px'
                                    quality={90}
                                  />
                                </ImageWrapper>
                                <span>{item}</span>
                              </SelectOptionContainer>
                            </SelectOption>
                          ))}
                        </SelectField>
                      </SelectControl>
                    </FormControlSelect>
                  );
                }}
              </Field>
              <LastFieldContainer>
                <Field
                  name='zipcode'
                  validate={zipCodeValidator}
                  defaultValue={filterZipCode}
                >
                  {({ meta, input: { name, onChange, value } }) => (
                    <StyledTextField
                      size='small'
                      name={name}
                      label='Your zip code'
                      placeholder='Enter your zip'
                      variant='outlined'
                      fullWidth
                      value={value}
                      sx={zipcodeInputStyles}
                      inputProps={{ maxLength: 5 }}
                      onChange={(e) => {
                        const zipCode = e.target.value;
                        const zipCodeNum = Number(zipCode);
                        if (
                          zipCode.length &&
                          (!isNumber(zipCodeNum) || isNaN(zipCodeNum))
                        ) {
                          return;
                        }
                        setFilterZipCode(zipCode);
                        onChange(e);
                      }}
                      error={meta.error && meta.touched}
                    />
                  )}
                </Field>
                <SearchSubmitButton />
              </LastFieldContainer>
            </CustomGrid>
          </form>
        )}
      />
    </FromContainer>
  );
};

export default SimpleSearchForm;
