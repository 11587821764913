import { useRecoilCallback } from 'recoil';
import { zipcodeValidatorSelectorFamily } from '../recoil';
import { softZipcodeValidate } from '../../../shared/utils/validators';
import { useCallback } from 'react';
import { INVALID_ZIP_CODE_ERROR_MSG } from 'shared/utils/constants';

type Settings = {
  onValidationEnd?: (isValid: boolean) => void;
};

export const useZipcodeValidator = () => {
  const softValidator = useCallback(
    (zipcode: string): { isValid: boolean; error?: string } => {
      const error = softZipcodeValidate(zipcode);
      return error ? { isValid: false, error } : { isValid: true };
    },
    [],
  );

  const remoteValidator = useRecoilCallback(
    ({ snapshot }) =>
      (zipcode: string) =>
        snapshot.getPromise(zipcodeValidatorSelectorFamily(zipcode)),
  );

  const isZipcodeValid = useCallback(
    async (zipcode: string, settings?: Settings) => {
      const { isValid: isValidSoft, error: softError } = softValidator(zipcode);

      if (!isValidSoft) {
        settings?.onValidationEnd?.(isValidSoft);
        return { isValid: isValidSoft, error: softError };
      }

      const { isValid: isValidRemote } = await remoteValidator(zipcode);
      settings?.onValidationEnd?.(isValidRemote);

      return {
        isValid: isValidRemote,
        error: isValidRemote ? undefined : INVALID_ZIP_CODE_ERROR_MSG,
      };
    },
    [softValidator, remoteValidator],
  );

  return { isZipcodeValid };
};
