import { FC, ReactNode, SyntheticEvent, useState } from 'react';
import { useRouter } from 'next/router';
import { QueryType } from '../../../containers/home';
import {
  AVAILABLE_MONTHLY_PAYMENTS,
  // FEATURE_FLAG,
} from '../../../shared/utils/constants';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '../../atoms/Tabs/Tab';
import Typography from '@mui/material/Typography';
import { StyledFontAwesomeIconMui5 } from '../../atoms/FontAwesomeIcon';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { alpha, styled } from '@mui/material/styles';
import { TabsOwnProps } from '@mui/material/Tabs/Tabs';
import { green, grey } from '../../../theme/colors';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { SxProps } from '@mui/system';
import { StandardLonghandProperties as CSSProps } from 'csstype';
import Rest from '../../../services/rest';
import SimpleSearchForm from '../../molecules/SearchForm/SimpleSearchForm';
import { AISearchForm } from '../../molecules/SearchForm/AISearchForm';
// import { useFeatureIsEnabled } from '../../../hooks/useFeatureIsEnabled';

const rest = new Rest();

const ribbonOverflowStylesFix: (
  theme: ThemeMui5,
) => SystemStyleObject<ThemeMui5> = () => ({
  '&.MuiTabs-root': {
    overflow: 'visible!important',
    '& .MuiTabs-scroller': {
      overflow: 'visible!important',
      '& .MuiTabs-flexContainer': {
        overflow: 'visible!important',
        '& .MuiTab-wrapper': {
          overflow: 'visible!important',
          '& .MuiTab-root': {
            overflow: 'visible!important',
          },
        },
      },
    },
  },
});

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tab-panel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tab-panel-${index}`,
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StyledTabsProps extends TabsOwnProps {}

const StylizedTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className='MuiTabs-indicatorSpan' />,
    }}
  />
))(({ theme }) => ({
  minHeight: 'unset',
  '& .MuiTabs-flexContainer': {
    gap: theme.spacing(1),
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    display: 'none',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StyledTabProps extends TabProps {
  wrapperProps?: {
    sx: SxProps<ThemeMui5>;
  };
}

const TabWrapper = styled('div', {
  shouldForwardProp: (props) => props !== 'sx',
})({});

const StyledTab = styled((props: StyledTabProps) => (
  <TabWrapper className='MuiTab-wrapper' {...props.wrapperProps}>
    <Tab {...props} />
  </TabWrapper>
))(({ theme }) => ({
  minHeight: 'unset',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(16),
  color: alpha(theme.palette.text.primary, 0.7),
  backgroundColor: grey[200],
  borderBottom: 'none',
  borderRadius: '16px 16px 0 0',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(0.5, 1.5),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.5, 1.5),
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  '&.Mui-disabled': {
    color: alpha(theme.palette.text.primary, 0.4),
    backgroundColor: grey[100],
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const ribbonPseudoStyles: (theme: ThemeMui5) => SystemStyleObject<ThemeMui5> = (
  theme,
) => {
  const fontSize = theme.typography.pxToRem(12);
  const height: CSSProps['height'] = '16px';
  const width: CSSProps['width'] = '40%';
  const text = 'beta';
  const top: CSSProps['top'] = 0;
  const left: CSSProps['left'] = '100%';
  const verticalOffset: CSSProps['height'] = 'calc(-100% + 4px)';
  const horizontalOffset: CSSProps['width'] = '-4px';
  const lightColor: CSSProps['color'] = alpha(green[600], 0.84);
  return {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&::after': {
      content: `"${text}"`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width,
      height,
      position: 'absolute',
      backgroundColor: lightColor,
      color: theme.palette.common.white,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize,
      borderRadius: '12px',
      top,
      left,
      transform: `translate(${verticalOffset}, ${horizontalOffset})`,
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.4)',
    },
  };
};

type OnSimpleSubmitArgs = {
  budget?: string;
  bodyStyle?: string;
  zipcode?: string;
};

type OnAiSubmitArgs = {
  query?: string;
};

type TopSearchBarProps = {
  onSimpleSubmit?: (args: OnSimpleSubmitArgs) => Promise<void> | void;
  onAiSubmit?: (args: OnAiSubmitArgs) => Promise<void> | void;
};

export const TopSearchBar: FC<TopSearchBarProps> = ({
  onSimpleSubmit,
  onAiSubmit,
}) => {
  const router = useRouter();
  const [tabIndex, setTabIndex] = useState(0);

  const onSimpleSubmitDefault = ({
    budget,
    bodyStyle,
    zipcode,
  }: OnSimpleSubmitArgs) => {
    const query: QueryType = {};
    if (budget) {
      const { min, max } = AVAILABLE_MONTHLY_PAYMENTS[budget] || {};
      if (Number.isFinite(Number(min))) {
        query.minMonthlyPayment = Number(min);
      }
      if (Number.isFinite(Number(max))) {
        query.maxMonthlyPayment = Number(max);
      }
    }
    if (bodyStyle) {
      query.body_style = bodyStyle;
    }
    if (zipcode) {
      query.zipcode = zipcode;
    }
    // noinspection JSIgnoredPromiseFromCall
    router.push({
      pathname: '/deals',
      query,
    });
  };

  const onAiSubmitDefault = async ({ query }) => {
    const searchResults = await rest.getSearchResults(query);
    if (!searchResults?.length) {
      return;
    }
    const url = new URL(searchResults);
    // noinspection JSIgnoredPromiseFromCall
    router.push({
      pathname: url.pathname,
      search: url.search,
    });
    return;
  };

  const simpleSubmit = onSimpleSubmit || onSimpleSubmitDefault;
  const aiSubmit = onAiSubmit || onAiSubmitDefault;

  const onTabChange = (_: SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  return (
    <Box className='js-search-bar' mt={3} mb={2}>
      <StylizedTabs
        value={tabIndex}
        onChange={onTabChange}
        centered
        sx={ribbonOverflowStylesFix}
      >
        <StyledTab
          {...a11yProps(0)}
          label={
            <Typography variant='body1' component='span' color='inherit'>
              <StyledFontAwesomeIconMui5 icon={faMagnifyingGlass} size='sm' />{' '}
              <b>Regular search</b>
            </Typography>
          }
        />
        <StyledTab
          {...a11yProps(1)}
          wrapperProps={{
            sx: ribbonPseudoStyles,
          }}
          label={
            <Typography variant='body1' component='span' color='inherit'>
              {/* this is the only free one icon, faSparkles comes with pro subscription */}
              <StyledFontAwesomeIconMui5 icon={faWandSparkles} size='sm' />{' '}
              <b>AI search</b>
            </Typography>
          }
        />
      </StylizedTabs>
      <TabPanel value={tabIndex} index={0}>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <SimpleSearchForm onSubmit={simpleSubmit} />
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <AISearchForm onSubmit={aiSubmit} />
        </Box>
      </TabPanel>
    </Box>
  );
};
