import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import { blue } from 'theme/colors';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import useWidth from 'hooks/useWidth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import NextLinkMui5 from '../../components/atoms/NextLink';
import LinkMui5 from '../../components/atoms/Link';
import { SeoLink } from 'shared/utils/seoLinksData';
import GridMui5 from '@mui/material/Grid';

const ClickGrid = styled('div')(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

const LIMIT_MOBILE = 4;
const LIMIT_TABLET = 8;
const LIMIT_DESKTOP = 12;

function getInitLimit(width: string): number {
  switch (width) {
    case 'xs':
      return LIMIT_MOBILE;
    case 'sm':
      return LIMIT_TABLET;
    default:
      return LIMIT_DESKTOP;
  }
}

export function SeoLinksGrid({
  links,
  formatName = (name) => name,
  classSuffix = '',
  onLinkClick = () => {},
}: {
  links: SeoLink[];
  formatName?: (name: string) => string;
  classSuffix?: string;
  onLinkClick?: (url: string) => void;
}): JSX.Element {
  const width = useWidth();
  const inititalLimit = getInitLimit(width);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleShowMore = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, [setIsOpen]);

  const limit = isOpen ? links.length : inititalLimit;

  if (classSuffix) {
    classSuffix = `-${classSuffix}`;
  }

  return (
    <>
      <GridMui5 container spacing={1}>
        {links.slice(0, limit).map((link) => (
          <GridMui5
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            key={link.name}
            onClick={() => onLinkClick(link.url)}
          >
            <LinkMui5
              variant='body2'
              component={NextLinkMui5}
              href={link.url}
              passHref={true}
            >
              {formatName(link.name)}
            </LinkMui5>
          </GridMui5>
        ))}
      </GridMui5>
      {links.length > 12 && (
        <ClickGrid onClick={toggleShowMore}>
          <Button
            className={
              isOpen
                ? `js-links-show-less${classSuffix}`
                : `js-links-show-more${classSuffix}`
            }
            sx={{
              paddingLeft: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disableRipple
            disableTouchRipple
            endIcon={
              <FontAwesomeIcon
                icon={isOpen ? faChevronUp : faChevronDown}
                color={blue[100]}
              />
            }
          >
            <Typography variant='body2'>
              {`view ${isOpen ? 'less' : 'more'}`}
            </Typography>
          </Button>
        </ClickGrid>
      )}
    </>
  );
}
