import { INVALID_ZIP_CODE_ERROR_MSG } from './constants';
import { getStateByZip } from '../services/geo';

export function isUsZipcode(value: string): boolean {
  const { state } = getStateByZip(value);
  return Boolean(state);
}

export const softZipcodeValidate = (value: string): string | undefined => {
  if (!value) {
    return INVALID_ZIP_CODE_ERROR_MSG;
  }
  if (value && value.trim().length < 4) {
    return INVALID_ZIP_CODE_ERROR_MSG;
  }
  if (value && !isUsZipcode(value)) {
    return INVALID_ZIP_CODE_ERROR_MSG;
  }
};
